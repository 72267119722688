import { getFullUrl } from 'hubspot-url-utils';
import { createClient } from 'usage-tracker-core';
import { genericClient } from 'usage-tracker-core/client';
import { sendEventsViaFetch } from 'usage-tracker-core/client/fetchClient';
import { attemptToGetCookieHstc } from './utils';
const origin = getFullUrl('app-api');
const path = '/usage-logging/v1/log/hublytics-multi/no-auth';
const reportNetworkError = err => {
  return genericClient.reportError(err, {
    fingerprint: ['usage-tracker-js', 'network']
  });
};
const publicTrackingClient = createClient({
  clientName: 'public',
  getDebug: genericClient.getDebug,
  getLang: genericClient.getLang,
  getTempStorage: genericClient.getTempStorage,
  setTempStorage: genericClient.setTempStorage,
  logWarning: genericClient.logWarning,
  logError: genericClient.logError,
  reportWarning: genericClient.reportWarning,
  reportError: genericClient.reportError,
  getHstc: attemptToGetCookieHstc,
  getEmail: () => null,
  getHubId: () => null,
  send: ({
    events,
    query
  }) => {
    const endpoint = `${origin}${path}?${query}`;
    const sendXhr = () => {
      sendEventsViaFetch(endpoint, {
        data: events
      }).catch(reportNetworkError);
    };
    genericClient.sendBeacon(endpoint, events, sendXhr);
  }
});
export default publicTrackingClient;