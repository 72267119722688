// @ts-expect-error Untyped import
import { eventProps } from '../selectors/eventProps';
import { getIsPrivateLoad } from '../../widget-data/selectors/getIsPrivateLoad';
import { getUsageTracker } from '../usageTracker';
// @ts-expect-error Untyped import
import { getIsUserTrackingAllowed } from '../../gdpr/selectors/getIsUserTrackingAllowed';
let trackOnce = false;
export function trackInteraction(eventName, properties = {}, flag = false) {
  return (_dispatch, getState) => {
    const tracker = getUsageTracker();
    const isPrivateLoad = getIsPrivateLoad(getState());
    const isUserTrackingAllowed = getIsUserTrackingAllowed(getState());
    if (isUserTrackingAllowed && !trackOnce) {
      tracker.track(eventName, Object.assign({}, properties, eventProps(getState()), {
        privateLoad: isPrivateLoad
      }));
      trackOnce = flag;
    }
  };
}